.d-flex {
  display: flex;

  &.icon {
    svg {
      min-width: 24px;
    }
  }

  &.carousel {
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
      display: none;
    }

    &.overflow-x {
      overflow-x: auto;
    }

    &.overflow-y {
      overflow-y: auto;
    }
  }

  &.space-between {
    justify-content: space-between;
  }

  &.space-around {
    justify-content: space-around;;
  }

  &.center {
    align-items: center;
  }

  &.left {
    align-items: flex-start;
  }

  &.right {
    align-items: flex-end;
  }

  &.column {
    flex-direction: column;
  }

  &.extra-extra-small-gap {
    gap: var(--extra-extra-small-gap);
  }

  &.extra-small-gap {
    gap: var(--extra-small-gap);
  }

  &.small-gap {
    gap: var(--small-gap);
  }

  &.medium-gap {
    gap: var(--medium-gap);
  }

  &.large-gap {
    gap: var(--large-gap);
  }

  &.extra-large-gap {
    gap: var(--extra-large-gap);
  }
}



