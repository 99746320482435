.form {
    display: flex;
    flex-direction: column;
    gap: 8px;
    &__content {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    &__error {
        color: var(--text-day-error);
        font-size: 14px;
        display: flex;
        justify-content: center;
    }
}

.dark {
    .form {
        &__error {
            color: var(--text-night-error);
        }
    }
}