.link {
  transition: text-decoration  500ms ease;
  &:hover {
    &.action, &.underline {
      text-decoration: underline;
    }
  }

  &:hover, &.active {
    &.action {
      color: var(--text-day-primary-action);
    }
  }
}

.dark {
  .link {
    &:hover, &.active {
      &.action {
        color: var(--text-night-primary-action);
      }
    }
  }
}