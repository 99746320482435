.image {
    &.object-fit {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    &.primary-border-radius {
        border-radius: var(--primary-border-radius);
    }

    &.secondary-border-radius {
        border-radius: var(--secondary-border-radius);
    }
}


