.container {
  &.primary-background {
    background: var(--bg-day-primary);
    color: var(--text-day-primary);
  }

  &.secondary-background {
    background: var(--bg-night-primary);
    color: var(--text-night-primary);
  }

  &:first-child {
    margin: 0 auto;
    padding: 0 32px;

    @media screen and (max-width: 600px) {
      padding: 0 16px;
    }
  }
}

.dark {
  .container {
    &.primary-background {
      background: var(--bg-night-primary);
      color: var(--text-night-primary);
    }

    &.secondary-background {
      background: var(--bg-day-primary);
      color: var(--text-day-primary);
    }
  }
}