.toggle-list {
    &__button {
        position: relative;
        width: 100%;
        padding: 16px 24px 16px 16px;
        background: var(--bg-day-primary-card);
        border-radius: var(--primary-border-radius);
        font-size: 18px;
        font-weight: 500;
        text-align: left;
        text-transform: uppercase;
        transition: color 500ms ease;

        &:hover, &.open {
            color: var(--text-day-primary-action);
        }

        &.open {
            border-radius: var(--primary-border-radius) var(--primary-border-radius) 0 0;

            &::after {
                content: "-";
                font-weight: 300;
                font-size: 44px;
                line-height: 12px;
            }
        }

        &::after {
            position: absolute;
            right: 25px;
            content: "+";
            font-weight: 300;
            font-size: 34px;
            line-height: 18px;
        }
    }

    &__menu {
        display: none;
        padding: 16px;
        background: var(--bg-day-primary-card);
        border-radius: 0 0 var(--primary-border-radius) var(--primary-border-radius);

        &.open {
            display: block;
        }
    }
}

.dark .toggle-list {
    &__button {
        background: var(--bg-night-primary-card);

        &:hover, &.open {
            color: var(--text-night-primary-action);
        }
    }

    &__menu {
        background: var(--bg-night-primary-card);
    }
}