:root {
    --light-1: #faf9f8;
    --light-2: #f6f3ef;
    --light-3: #f2ece6;
    --light-4: #eee4dd;
    --light-5: #e9ded4;

    --sky-1: #bbeafb;
    --sky-2: #8edcf8;
    --sky-3: #7fc7ff;
    --sky-4: #4db2ff;
    --sky-5: #0676c0;
    --sky-6: #045e9a;

    --green-1: #dcf897;
    --green-2: #c0e75b;
    --green-3: #abe852;
    --green-4: #96e322;
    --green-5: #456c06;

    --dark-1: #191c22;
    --dark-2: #23272f;
    --dark-3: #2d323b;
    --dark-4: #333a45;
    --dark-5: #404756;
    --dark-6: #99a1b3;

    --blue-1: #a8b9fa;
    --news-1: #ffdb4c;

    //----------------Text----------------
    --text-day-primary: var(--dark-1);
    --text-night-primary: var(--light-1);

    //Button
    --text-primary-buttons: var(--light-1);

    --text-day-secondary-buttons: var(--dark-1);
    --text-night-secondary-buttons: var(--light-1);

    --text-tertiary-buttons: var(--dark-1);
    --text-quadruple-buttons: var(--dark-1);

    --text-day-primary-ghost-buttons: var(--dark-1);
    --text-night-primary-ghost-buttons: var(--light-1);

    --text-day-secondary-ghost-buttons: var(--dark-1);
    --text-day-secondary-ghost-buttons-hover: var(--sky-5);
    --text-night-secondary-ghost-buttons: var(--light-1);
    --text-night-secondary-ghost-buttons-hover: var(--sky-4);

    //Dropdown
    --text-secondary-dropdown: var(--light-1);

    //Input
    --text-day-input: var(--dark-5);
    --text-night-input: var(--light-5);

    //Other
    --text-day-primary-action: var(--sky-6);
    --text-night-primary-action: var(--sky-4);
    --text-primary-action: var(--sky-4);
    --text-secondary-action: var(--sky-5);

    --text-day-error: var(--dark-1);
    --text-night-error: var(--light-1);


    //----------------Background----------------
    --bg-day-primary: var(--light-1);
    --bg-night-primary: var(--dark-1);

    //Button
    --bg-primary-buttons: var(--sky-5);
    --bg-primary-buttons-hover: var(--sky-6);
    --bg-primary-buttons-pressed: var(--sky-6);

    --bg-day-secondary-buttons: var(--light-3);
    --bg-day-secondary-buttons-hover: var(--light-4);
    --bg-day-secondary-buttons-pressed: var(--light-4);
    --bg-night-secondary-buttons: var(--dark-3);
    --bg-night-secondary-buttons-hover: var(--dark-4);
    --bg-night-secondary-buttons-pressed: var(--dark-4);

    --bg-day-tertiary-buttons: var(--sky-1);
    --bg-day-tertiary-buttons-hover: var(--sky-2);
    --bg-day-tertiary-buttons-pressed: var(--sky-2);
    --bg-night-tertiary-buttons: var(--sky-3);
    --bg-night-tertiary-buttons-hover: var(--sky-4);
    --bg-night-tertiary-buttons-pressed: var(--sky-4);

    --bg-day-quadruple-buttons: var(--green-1);
    --bg-day-quadruple-buttons-hover: var(--green-2);
    --bg-day-quadruple-buttons-pressed: var(--green-2);
    --bg-night-quadruple-buttons: var(--green-3);
    --bg-night-quadruple-buttons-hover: var(--green-4);
    --bg-night-quadruple-buttons-pressed: var(--green-4);

    //Dropdown
    --bg-secondary-dropdown: var(--sky-5);
    --bg-secondary-dropdown-hover: var(--sky-6);

    --bg-day-dropdown-menu: var(--light-2);
    --bg-night-dropdown-menu: var(--dark-2);

    //Input
    --bg-day-primary-input: var(--light-3);
    --bg-day-primary-input-hover: var(--light-4);
    --bg-night-primary-input: var(--dark-3);
    --bg-night-primary-input-hover: var(--dark-4);
    --bg-day-secondary-input: var(--light-4);
    --bg-day-secondary-input-hover: var(--light-5);
    --bg-night-secondary-input: var(--dark-4);
    --bg-night-secondary-input-hover: var(--dark-5);

    //Card
    --bg-day-primary-card: var(--light-2);
    --bg-day-secondary-card: var(--light-3);
    --bg-night-primary-card: var(--dark-2);
    --bg-night-secondary-card: var(--dark-3);
    --bg-light-card: var(--light-2);
    --bg-light-sky-card: var(--sky-1);
    --bg-sky-card: var(--sky-2);
    --bg-blue-card: var(--blue-1);
    --bg-light-green-card: var(--green-1);
    --bg-green-card: var(--green-2);

    --bg-night-card: var(--dark-2);

    //Other - not use
    --bg-day-primary-action:  var(--sky-6);
    --bg-night-primary-action:  var(--sky-4);
    --bg-secondary-action:  var(--sky-5);

    --separator-day-primary: var(--dark-6);
    --separator-night-primary: var(--dark-3);

    --primary-border-radius: 8px;
    --secondary-border-radius: 2em;

    --extra-extra-small-gap: 4px;
    --extra-small-gap: 8px;
    --small-gap: 12px;
    --medium-gap: 16px;
    --large-gap: 24px;
    --extra-large-gap: 32px;
}

body {
    background: var(--bg-day-primary);
    color: var(--text-day-primary);
    font-family: 'Exo 2', sans-serif;
    font-weight: 300;
    line-height: 24px;
}

.dark body {
    background: var(--bg-night-primary);
    color: var(--text-night-primary);
}

*::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

*::-webkit-scrollbar-track {
    background: none;
}

*::-webkit-scrollbar-thumb {
    background: rgba(203, 193, 186, 0.6);
    border-radius: var(--primary-border-radius);
}

.dark {
    *::-webkit-scrollbar-thumb {
        background: rgba(51, 58, 69, 0.47);
    }
}

p {
    margin-bottom: 15px;
}

/* ~~~~~~~~~~~~~~~~~~~ Base ~~~~~~~~~~~~~~~~~~~ */

.toast {
    display: flex;
    justify-content: space-between;
}

.toast button {
    width: 29px;
}






