.button {
    max-width: 100%;
    padding: 8px 24px;
    @media screen and (max-width: 600px) {
        &.primary-border-radius {
            padding: 8px 16px;
        }
    }

    &.extra-large {
        font-size: 18px;
        line-height: 26px;
        font-weight: 500;
        min-height: 75px;
    }

    &.large {
        font-size: 18px;
        line-height: 26px;
        font-weight: 500;
        min-height: 55px;
    }

    &.medium-large {
        min-height: 45px;
    }

    &.medium {
        min-height: 40px;
    }

    &.small {
        height: 40px;
        width: 40px;
        padding: 0;
    }

    &.primary {
        color: var(--text-primary-buttons);
        background: var(--bg-primary-buttons);
        transition: background ease .5s;

        &:hover {
            background: var(--bg-primary-buttons-hover);
        }

        &:active, &.active {
            background: var(--bg-primary-buttons-pressed);
        }
    }

    &.secondary {
        color: var(--text-day-secondary-buttons);
        background: var(--bg-day-secondary-buttons);
        transition: background ease 0.5ms;

        &:hover {
            background: var(--bg-day-secondary-buttons-hover);
        }

        &:active, &.active {
            background: var(--bg-day-secondary-buttons-pressed);
        }
    }

    &.tertiary {
        color: var(--text-tertiary-buttons);
        background: var(--bg-day-tertiary-buttons);
        transition: background ease 0.5ms;

        &:hover {
            background: var(--bg-day-tertiary-buttons-hover);
        }

        &:active, &.active {
            background: var(--bg-day-tertiary-buttons-pressed);
        }
    }

    &.quadruple {
        color: var(--text-quadruple-buttons);
        background: var(--bg-day-quadruple-buttons);
        transition: background ease 0.5ms;

        &:hover {
            background: var(--bg-day-quadruple-buttons-hover);
        }

        &:active, &.active {
            background: var(--bg-day-quadruple-buttons-pressed);
        }
    }

    &.primary-ghost {
        box-shadow: inset 0 0 0 1px var(--text-day-primary-ghost-buttons);
        transition: box-shadow 500ms ease;

        &:hover, &.active {
            box-shadow: inset 0 0 0 2px var(--text-day-primary-ghost-buttons);
        }
    }

    &.secondary-ghost {
        box-shadow: inset 0 0 0 2px var(--text-day-secondary-ghost-buttons);
        transition: box-shadow 500ms ease, color 500ms ease;

        &:hover, &.active {
            color: var(--text-day-secondary-ghost-buttons-hover);
            box-shadow: inset 0 0 0 2px var(--text-day-secondary-ghost-buttons-hover);
        }

        &.medium {
            box-shadow: inset 0 0 0 1px;
        }
    }

    &.primary-border-radius {
        border-radius: var(--primary-border-radius);
    }

    &.secondary-border-radius {
        border-radius: var(--secondary-border-radius);
    }

    &.nowrap {
        white-space: nowrap;
    }

    &.min {
        width: 400px;
    }

    &.max {
        width: 100%;
    }

    &.flex {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: var(--medium-gap);
        @media screen and (max-width: 600px) {
            justify-content: center;
            svg {
                display: none;
            }
        }
    }
}

.link {
    transition: text-decoration ease .5s;
    text-align: left;
    line-height: 24px;
    &:hover {
        text-decoration: underline;
    }
}

.dark {
    .button {
        &.secondary {
            color: var(--text-night-secondary-buttons);
            background: var(--bg-night-secondary-buttons);

            &:hover {
                background: var(--bg-night-secondary-buttons-hover);
            }

            &:active, &.active {
                background: var(--bg-night-secondary-buttons-pressed);
            }
        }

        &.tertiary {
            background: var(--bg-night-tertiary-buttons);

            &:hover {
                background: var(--bg-night-tertiary-buttons-hover);
            }

            &:active, &.active {
                background: var(--bg-night-tertiary-buttons-pressed);
            }
        }

        &.quadruple {
            background: var(--bg-night-quadruple-buttons);

            &:hover {
                background: var(--bg-night-quadruple-buttons-hover);
            }

            &:active, &.active {
                background: var(--bg-night-quadruple-buttons-pressed);
            }
        }

        &.primary-ghost {
            box-shadow: inset 0 0 0 1px var(--text-night-primary-ghost-buttons);

            &:hover, &.active {
                box-shadow: inset 0 0 0 2px var(--text-night-primary-ghost-buttons);
            }
        }

        &.secondary-ghost {
            box-shadow: inset 0 0 0 1px var(--text-night-secondary-ghost-buttons);

            &:hover, &.active {
                color: var(--text-night-secondary-ghost-buttons-hover);
                box-shadow: inset 0 0 0 2px var(--text-night-secondary-ghost-buttons-hover);
            }
        }
    }
}