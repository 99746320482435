.dropdown {
    &:hover {
        .dropdown-menu {
            display: block;
            position: absolute;
            z-index: 1000;
            > div {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 8px;
            }
        }
    }

    .dropdown-menu {
        display: none;
        padding: 12px 16px;
        white-space: normal;
        background: var(--bg-day-dropdown-menu);
        border-radius: var(--primary-border-radius);

        &.max {
            width: 100%;
        }

        &.min {
            min-width: 200px;
            max-width: 250px;
        }
    }

    &.secondary {
        position: relative;

        .dropdown-toggle {
            border-radius: var(--primary-border-radius);
            background: var(--bg-secondary-dropdown);
            color: var(--text-secondary-dropdown);
            height: 40px;
            padding: 8px 24px;
            transition: background .5s ease, color .5s ease;

            &:hover {
                background: var(--bg-secondary-dropdown-hover);
            }
        }
    }
}

.dark {
    .dropdown {
        .dropdown-menu {
            background: var(--bg-night-dropdown-menu);
        }
    }
}
