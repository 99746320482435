* {
    padding: 0;
    margin: 0;
    border: none;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

a, a:link, a:visited, a:focus, a:hover, a:active {
    text-decoration: none;
    color: inherit;
}

aside, nav, footer, header, section, main {
    display: block;
}

h1, h2, h3, h4, h5, h6, p {
    font-size: inherit;
    font-weight: inherit;
}

ul, ul li {
    list-style: none;
}

img {
    vertical-align: top;
}

img, svg {
    max-width: 100%;
    height: auto;
}

address {
    font-style: normal;
}

input, textarea, button, select, input:-webkit-autofill {
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit;
    color: inherit;
    background-color: transparent;
}

input:-moz-placeholder, input::-moz-placeholder {
    opacity: 1;
}

input {
    &::-ms-clear {
        display: none;
    }
}

input[type="number"] {
    -moz-appearance: textfield;
    -webkit-appearance: textfield;
    appearance: textfield;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    display: none;
}

button, input[type="submit"] {
    display: inline-block;
    box-shadow: none;
    background: transparent none;
    cursor: pointer;
}

input:focus, input:active,
button:focus, button:active {
    outline: none;
}

button {
    &::-moz-focus-inner {
        padding: 0;
        border: 0;
    }
}

label {
    cursor: pointer;
}

legend {
    display: block;
}