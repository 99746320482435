.overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background: var(--text-day-primary);
    opacity: 0;
    visibility: hidden;
    transition: visibility 0.25s ease-in, opacity  0.25s ease;

    &.visible {
        visibility: visible;
        opacity: .25;
    }

    &__modal {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 16px;
        right: 16px;
        z-index: 1001;
        opacity: 0;
        visibility: hidden;
        transition: visibility 0.25s ease-in, opacity  0.25s ease;

        &.visible {
            visibility: visible;
            opacity: 1;
        }

        &__content {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.dark {
    .overlay {
        background: var(--text-night-primary);

        &.visible {
            opacity: .05;
        }
    }
}
