.text {
  &.pre-wrap {
    white-space: pre-wrap;
  }

  &.nowrap {
    white-space: nowrap;
  }

  &.xxs {
    font-size: 12px;
    line-height: 14px;
  }

  &.xs {
    font-size: 14px;
    line-height: 20px;
  }

  &.s {
    font-size: 16px;
    line-height: 24px;
  }

  &.m {
    font-size: 18px;
    line-height: 26px;
  }

  &.l {
    font-size: 20px;
    line-height: 28px;
  }

  &.xl {
    font-size: 24px;
    line-height: 32px;
    @media screen and (max-width: 600px) {
      font-size: 20px;
      line-height: 28px;
    }
  }

  &.xxl {
    font-size: 32px;
    line-height: 40px;
    @media screen and (max-width: 600px) {
      font-size: 28px;
      line-height: 34px;
    }
  }

  &.light {
    font-weight: 300;
  }

  &.regular {
    font-weight: 400;
  }

  &.medium {
    font-weight: 500;
  }

  &.bold {
    font-weight: 700;
  }

  &.extra-bold {
    font-weight: 800;
  }

  &.center {
    text-align: center;
  }

  &.left {
    text-align: left;
  }

  &.right {
    text-align: right;
  }

  &.text-secondary-action {
    color: var(--text-secondary-action);
  }

  &.text-day-primary {
    color: var(--text-day-primary);
  }

  &.text-primary-action {
    color: var(--text-day-primary-action);
  }

  &.text-invert-action {
    color: var(--text-secondary-action);
  }

  &.text-tertiary {
    color: var(--text-tertiary);
  }

  &.text-quadruple {
    color: var(--text-quadruple);
  }

  &.font-primary {
    font-family: 'Exo 2', sans-serif;
  }

  &.font-secondary {
    font-family: 'Roboto', sans-serif;
  }

  &.transform-uppercase {
    text-transform: uppercase;
  }

  &.line-clamp {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }

  &.line-clamp-1 {
    line-clamp: 1;
    -webkit-line-clamp: 1;
  }

  &.line-clamp-2 {
    line-clamp: 2;
    -webkit-line-clamp: 2;
  }
  
  &.flex {
    display: flex;
  }
}

.dark {
  .text {
    &.text-primary-action {
      color: var(--text-night-primary-action);
    }

    &.text-invert-action {
      color: var(--text-day-primary-action);
    }
  }
}