.columns {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 24px;

    &.center > div {
        width: 100%;
        margin: 0 auto;
    }

    &.extra-small-row-gap {
        row-gap: 8px;
    }

    &.small-row-gap {
        row-gap: 16px;
    }

    &.medium-row-gap {
        row-gap: 24px;
    }

    &.large-row-gap {
        row-gap: 32px;
    }

    &.extra-large-row-gap {
        row-gap: 60px;
    }

    &__4 {
        &.large {
            > div {
                @media screen and (max-width: 400px)  {
                    flex: 1 1 100%;
                    width: 100%;
                }

                @media screen and (min-width: 400px) and (max-width: 600px) {
                    flex: 1 1 33%;
                    max-width: calc((100% - 24px)/2);
                }

                @media screen and (min-width: 600px) and (max-width: 800px) {
                    flex: 1 1 25%;
                    max-width: calc((100% - 2*24px)/3);
                }

                @media screen and (min-width: 800px) {
                    flex: 1 1 20%;
                    max-width: calc((100% - 3*24px)/4);
                }
            }
        }
        &.medium {
            > div {
                @media screen and (max-width: 600px)  {
                    flex: 1 1 100%;
                    width: 100%;
                }

                @media screen and (min-width: 600px) and (max-width: 800px) {
                    flex: 1 1 33%;
                    max-width: calc((100% - 24px)/2);
                }

                @media screen and (min-width: 800px) and (max-width: 1000px) {
                    flex: 1 1 25%;
                    max-width: calc((100% - 2*24px)/3);
                }

                @media screen and (min-width: 1000px) {
                    flex: 1 1 20%;
                    max-width: calc((100% - 3*24px)/4);
                }
            }
        }
    }

    &__3 {
        &.large {
            > div {
                @media screen and (max-width: 400px)  {
                    flex: 1 1 100%;
                    width: 100%;
                }

                @media screen and (min-width: 400px) and (max-width: 800px) {
                    flex: 1 1 33%;
                    max-width: calc((100% - 24px)/2);
                }

                @media screen and (min-width: 800px) {
                    flex: 1 1 25%;
                    max-width: calc((100% - 2*24px)/3);
                }
            }
        }
        &.medium {
            > div {
                @media screen and (max-width: 800px)  {
                    flex: 1 1 100%;
                    width: 100%;
                }

                @media screen and (min-width: 800px) and (max-width: 1000px) {
                    flex: 1 1 33%;
                    max-width: calc((100% - 24px)/2);
                }

                @media screen and (min-width: 1000px) {
                    flex: 1 1 25%;
                    max-width: calc((100% - 2*24px)/3);
                }
            }
        }
    }

    &__2 {
        &.large {
            > div {
                @media screen and (max-width: 800px) {
                    flex: 1 1 100%;
                    width: 100%;
                }

                @media screen and (min-width: 800px) {
                    flex: 1 1 33%;
                    max-width: calc((100% - 24px)/2);
                }
            }
        }
        &.medium {
            > div {
                @media screen and (max-width: 1000px) {
                    flex: 1 1 100%;
                    width: 100%;
                }

                @media screen and (min-width: 1000px) {
                    flex: 1 1 33%;
                    max-width: calc((100% - 24px)/2);
                }
            }
        }
    }
}