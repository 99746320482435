.loader {
    position: absolute;
    left: calc(50% - 50px);
    top: calc(50% - 50px);

    &::after, &::before {
        content: '';
        box-sizing: border-box;
        width: 100px;
        height: 100px;
        border: 4px solid var(--text-primary-action);
        position: absolute;
        left: 0;
        top: 0;
        animation: rotationBreak 3s ease-in-out infinite alternate;
    }

    &::after {
        border-color: var(--text-secondary-action);
        animation-direction: alternate-reverse;
    }
}

@keyframes rotationBreak {
    0% {
        transform: rotate(0);
    }
    25% {
        transform: rotate(90deg);
    }
    50% {
        transform: rotate(180deg);
    }
    75% {
        transform: rotate(270deg);
    }
    100% {
        transform: rotate(360deg);
    }
}