.field {
    max-width: 100%;
    color: var(--text-day-input);

    &.error {
        color: var(--text-day-error);
    }

    &.large {
        width: 580px;
    }

    &.medium {
        width: 280px;
    }

    &.max {
        width: 100%;
    }

    .input {
        position: relative;
        padding: 8px 24px;

        &.primary {
            background: var(--bg-day-primary-input);
            &:hover, &:focus-within, &:active, &.active {
                background: var(--bg-day-primary-input-hover);
                input {
                    &:-webkit-autofill {
                        -webkit-box-shadow: 0 0 0 1000px var(--bg-day-primary-input-hover) inset;
                    }
                    &::file-selector-button {
                        background: var(--bg-day-primary-input-hover);
                    }
                }
            }

        }

        &.secondary {
            background: var(--bg-day-secondary-input);
            &:hover, &:focus-within, &:active, &.active {
                background: var(--bg-day-secondary-input-hover);
                input {
                    &:-webkit-autofill {
                        -webkit-box-shadow: 0 0 0 1000px var(--bg-day-secondary-input-hover) inset;
                    }
                    &::file-selector-button {
                        background: var(--bg-day-secondary-input-hover);
                    }
                }
            }
        }

        &.primary-border-radius {
            border-radius: var(--primary-border-radius);
        }

        &.secondary-border-radius {
            border-radius: var(--secondary-border-radius);
        }

        &.disabled {
            opacity: 0.8;
        }

        &.disabled:hover {
            box-shadow: none;
        }

        &.large {
            height: 60px;
        }

        &.medium {
            height: 40px;
        }

        .label {
            display: block;
            position: absolute;
            font-size: 12px;
            line-height: 18px;
        }

        input {
            width: 100%;
            height: 100%;
            &:-webkit-autofill {
                -webkit-text-fill-color: var(--text-day-input);
            }
            &::-webkit-input-placeholder {
                color: var(--text-day-input);
            }

            &::file-selector-button {
                border: none;
                color: var(--text-day-input);
            }

            &.with-label {
                padding-top: 14px;
            }

            &.primary {
                &:-webkit-autofill {
                    -webkit-box-shadow: 0 0 0 1000px var(--bg-day-primary-input) inset;
                }
                &::file-selector-button {
                    background: var(--bg-day-primary-input);
                }
            }

            &.secondary {
                &:-webkit-autofill {
                    -webkit-box-shadow: 0 0 0 1000px var(--bg-day-secondary-input) inset;
                }
                &::file-selector-button {
                    background: var(--bg-day-secondary-input);
                }
            }

            &.error {
                &:-webkit-autofill,
                &:-webkit-autofill:focus {
                    -webkit-text-fill-color: var(--text-day-error);
                }
                &::-webkit-input-placeholder {
                    color: var(--text-day-error);
                }
            }

        }

        .input-icon {
            position: absolute;
            top: 24px;
            right: 24px;
        }

        &.medium .input-icon {
            top: 10px;
            right: 22px;
        }
    }

    &__error {
        text-align: left;
        margin-top: 4px;
        font-size: 12px;
        line-height: 18px;
    }
}

.dark {
    .field {
        color: var(--text-night-input);

        &.error {
            color: var(--text-night-error);
        }

        .input {
            &.primary {
                background: var(--bg-night-primary-input);
                &:hover, &:focus, &:focus-within, &:active, &.active {
                    background: var(--bg-night-primary-input-hover);
                    input {
                        &:-webkit-autofill {
                            -webkit-box-shadow: 0 0 0 1000px var(--bg-night-primary-input-hover) inset;
                        }
                        &::file-selector-button {
                            background: var(--bg-night-primary-input-hover);
                        }
                    }
                }

            }

            &.secondary {
                background: var(--bg-night-secondary-input);
                &:hover, &:focus, &:focus-within, &:active, &.active {
                    background: var(--bg-night-secondary-input-hover);
                    input {
                        &:-webkit-autofill {
                            -webkit-box-shadow: 0 0 0 1000px var(--bg-night-secondary-input-hover) inset;
                        }
                        &::file-selector-button {
                            background: var(--bg-night-secondary-input-hover);
                        }
                    }
                }
            }

            input {
                &:-webkit-autofill {
                    -webkit-text-fill-color: var(--text-night-input);
                }
                &::-webkit-input-placeholder {
                    color: var(--text-night-input);
                }
                &::file-selector-button {
                    background: var(--text-night-input);
                }

                &.primary {
                    &:-webkit-autofill {
                        -webkit-box-shadow: 0 0 0 1000px var(--bg-night-primary-input) inset;
                    }
                    &::file-selector-button {
                        background: var(--bg-night-primary-input);
                    }
                }

                &.secondary {
                    &:-webkit-autofill {
                        -webkit-box-shadow: 0 0 0 1000px var(--bg-night-secondary-input) inset;
                    }
                    &::file-selector-button {
                        background: var(--bg-night-secondary-input);
                    }
                }

                &.error {
                    &:-webkit-autofill,
                    &:-webkit-autofill:focus {
                        -webkit-text-fill-color: var(--text-night-error);
                    }
                    &::-webkit-input-placeholder {
                        color: var(--text-night-error);
                    }
                }

            }
        }
    }
}