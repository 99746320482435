.aside-menu {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 11;

  width: 0;
  max-width: 100%;
  background: var(--bg-day-primary);
  overflow-x: hidden;
  visibility: hidden;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out, visibility 0.3s ease-in-out, width 0.3s ease-in-out;

  &.visible {
    @media screen and (max-width: 600px)  {
      width: 75%;
    }
    @media screen and (min-width: 600px) and (max-width: 900px)  {
      width: 60%;
    }
    width: 45%;
    visibility: visible;

    &:not(.hiding) {
      transform: translateX(0);
    }
  }

  &__content {
    padding: 24px;
  }
}

.dark {
  .aside-menu {
    background: var(--bg-night-primary);
  }
}