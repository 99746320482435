.card {
  &.primary-border-radius {
    border-radius: var(--primary-border-radius);
  }

  &.secondary-border-radius {
    border-radius: var(--secondary-border-radius);
  }

  &.primary {
    background-color: var(--bg-day-primary-card);
  }

  &.secondary {
    background-color: var(--bg-day-secondary-card);
  }

  &.extra-small-padding {
    padding: 8px;
  }

  &.small-padding {
    padding: 16px;
  }

  &.small-padding {
    padding: 16px;
  }

  &.small-medium-padding {
    padding: 16px 24px;
  }

  &.medium-padding {
    padding: 24px;
  }

  &.large-padding {
    padding: 32px;
  }

  &.large-extra-large-padding {
    padding: 32px 40px;
  }

  &.extra-large-padding {
    padding: 40px;
  }
}

.dark {
  .card {
    &.primary {
      background-color: var(--bg-night-primary-card);
    }

    &.secondary {
      background-color: var(--bg-night-secondary-card);
    }
  }
}



