.background {
  &.light {
    background: var(--bg-light-card);
  }

  &.light-sky {
    background: var(--bg-light-sky-card);
  }

  &.sky {
    background: var(--bg-sky-card);
  }

  &.blue {
    background: var(--bg-blue-card);
  }

  &.light-green {
    background: var(--bg-light-green-card);
  }

  &.green {
    background: var(--bg-green-card);
  }

  &.primary-border-radius {
    border-radius: var(--primary-border-radius);
  }

  &.secondary-border-radius {
    border-radius: var(--secondary-border-radius);
  }
}

.dark {
  .background {
    background: var(--bg-night-card);
  }
}



