.title {
  font-family: 'Roboto', sans-serif;
  font-size: 40px;
  line-height: 46px;
  font-weight: 700;
  text-transform: uppercase;

  @media screen and (max-width: 600px) {
    font-size: 32px;
    line-height: 40px;
  }

  &.center {
    text-align: center;
  }

  &.left {
    text-align: left;
  }

  &.right {
    text-align: right;
  }
}